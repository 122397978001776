<template>
  <div>
    <form @submit.prevent="save">
      <h4>Año de declaración jurada</h4>
      <div class="form-group">
        <label class="form-label">Año</label>
        <select
          id="otro_empleo_percibe_asignaciones"
          class="form-control"
          :disabled="editMode"
          v-model="percepcionCargasFamilia.year"
          style="width: 150px"
          :class="{
            'readonly': editMode,
            'is-invalid': enviado && $v.percepcionCargasFamilia.year.$invalid
          }"
        >
          <option :value="null" hidden>-</option>
          <option :value="0" hidden>-</option>
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
        <small class="form-message text-danger"
               v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_percibe_asignaciones.$invalid">
          Éste campo es obligatorio
        </small>
      </div>
      <h4>Datos del/la trabajador/a</h4>
      <div class="cols2">
        <pd-input
          v-model="percepcionCargasFamilia.dni_empleado"
          id="dni_empleado"
          type="number"
          label="DNI empleado *"
          placeholder="DNI empleado"
          readonly="readonly"
          :required="enviado && $v.percepcionCargasFamilia.dni_empleado.$invalid"
        />
        <pd-input
          v-model="percepcionCargasFamilia.cuil_empleado"
          id="cuil_empleado"
          type="number"
          label="CUIL empleado *"
          placeholder="CUIL empleado"
          readonly="readonly"
          :required="enviado && $v.percepcionCargasFamilia.cuil_empleado.$invalid"
        />
        <pd-input
          v-model="percepcionCargasFamilia.nombre_empleado"
          id="nombre_empleado"
          label="Nombre empleado *"
          placeholder="Nombre empleado"
          readonly="readonly"
          :required="enviado && $v.percepcionCargasFamilia.nombre_empleado.$invalid"
        />
        <div class="form-group">
          <label class="form-label">¿Tiene otro empleo? *</label>
          <select
            id="otro_empleo"
            class="form-control"
            v-model="percepcionCargasFamilia.otro_empleo"
            :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo.$invalid}"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="true">SI</option>
            <option :value="false">NO</option>
          </select>
          <small class="form-message text-danger" v-if="enviado && $v.percepcionCargasFamilia.otro_empleo.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
      </div>
      <template v-if="percepcionCargasFamilia.otro_empleo">
        <h5 class="my-1 mx-1">Datos otro empleo</h5>
        <div class="cols2">
          <div class="form-group d-flex align-end">
            <label class="form-label">Recibo de sueldo *</label>
            <label class="d-flex left btn-flat-dark text-small form-control"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo_recibo_sueldo.$invalid}"
                   style="padding: .5rem"
                   for="otro_empleo_recibo_sueldo_edit">
              <icon feather="edit" style="height:1rem"/>
              <span v-if="percepcionCargasFamilia.otro_empleo_recibo_sueldo" class="px-1" style="margin-top: .25rem">
                  {{ percepcionCargasFamilia.otro_empleo_recibo_sueldo_original_name }}
              </span>
              <span v-else class="px-1"
                    style="margin-top: .25rem">Subir recibo de sueldo</span>
              <input
                type="file"
                id="otro_empleo_recibo_sueldo_edit"
                @change="changeEditOtroEmpleoReciboSueldo"
                style="display: none"
              />
            </label>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_recibo_sueldo.$invalid"
            >Éste campo es obligatorio.</small
            >
          </div>
          <div></div>
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_cuit_empleador"
            id="otro_empleo_cuit_empleador"
            label="CUIT de empleador *"
            type="number"
            placeholder="CUIT de empleador *"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_cuit_empleador.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_razon_social_empleador"
            id="otro_empleo_razon_social_empleador"
            type="text"
            label="Razón social empleador *"
            placeholder="Razón social empleador *"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_razon_social_empleador.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_fecha_ingreso"
            id="otro_empleo_fecha_ingreso"
            label="Fecha de ingreso*"
            placeholder="Fecha de ingreso*"
            type="date"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_fecha_ingreso.$invalid"
          />
          <div></div>
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_calle_domicilio_empleador"
            id="otro_empleo_calle_domicilio_empleador"
            label="Calle *"
            placeholder="Calle *"
            type="text"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_calle_domicilio_empleador.$invalid"
          />
          <div class="cols3" style="margin:0">
            <pd-input
              v-model="percepcionCargasFamilia.otro_empleo_numero_domicilio_empleador"
              id="otro_empleo_numero_domicilio_empleador"
              label="Número *"
              placeholder="Número *"
              type="number"
              :required="enviado && $v.percepcionCargasFamilia.otro_empleo_numero_domicilio_empleador.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.otro_empleo_piso_domicilio_empleador"
              id="otro_empleo_piso_domicilio_empleador"
              label="Piso"
              placeholder="Piso"
              type="text"
            />
            <pd-input
              v-model="percepcionCargasFamilia.otro_empleo_dpto_domicilio_empleador"
              id="otro_empleo_dpto_domicilio_empleador"
              label="Dpto"
              placeholder="Dpto"
              type="text"
            />
          </div>
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_codigo_postal_empleador"
            id="otro_empleo_codigo_postal_empleador"
            label="Código postal *"
            placeholder="Código postal *"
            type="number"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_codigo_postal_empleador.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_localidad_empleador"
            id="otro_empleo_localidad_empleador"
            label="Localidad *"
            placeholder="Localidad *"
            type="text"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_localidad_empleador.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.otro_empleo_provincia_empleador"
            id="otro_empleo_provincia_empleador"
            label="Provincia *"
            placeholder="Provincia *"
            type="text"
            :required="enviado && $v.percepcionCargasFamilia.otro_empleo_provincia_empleador.$invalid"
          />
        </div>
        <div class="cols2">
          <div
            class="form-group">
            <label class="form-label">¿Percibe asignaciones familiares en el otro empleo? *</label>
            <select
              id="otro_empleo_percibe_asignaciones"
              class="form-control"
              v-model="percepcionCargasFamilia.otro_empleo_percibe_asignaciones"
              :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo_percibe_asignaciones.$invalid}"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_percibe_asignaciones.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
          <div class="form-group d-flex align-end"
               v-if="percepcionCargasFamilia.otro_empleo && !percepcionCargasFamilia.otro_empleo_percibe_asignaciones"
          >
            <label class="form-label">Certificado de no percepción</label>
            <label class="d-flex left btn-flat-dark text-small form-control"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo_certificado_no_percepcion.$invalid}"
                   style="padding: .5rem"
                   for="otro_empleo_certificado_no_percepcion_edit">
              <icon feather="edit" style="height:1rem"/>
              <span v-if="percepcionCargasFamilia.otro_empleo_certificado_no_percepcion" class="px-1"
                    style="margin-top: .25rem">
                  {{ percepcionCargasFamilia.otro_empleo_certificado_no_percepcion_original_name }}
              </span>
              <span v-else class="px-1"
                    style="margin-top: .25rem">Subir certificado de no percepción</span>
              <input
                type="file"
                id="otro_empleo_certificado_no_percepcion_edit"
                @change="changeEditOtroEmpleoCertificadoNoPercepcion"
                style="display: none"
              />
            </label>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_certificado_no_percepcion.$invalid"
            >Éste campo es obligatorio.</small
            >
          </div>
        </div>
      </template>
      <div class="cols2 mt-1">
        <div class="form-group">
          <label class="form-label">¿Trabaja en forma independiente (Autónomo/Monotributista)? *</label>
          <select
            id="otro_empleo_trabajador_independiente"
            class="form-control"
            v-model="percepcionCargasFamilia.otro_empleo_trabajador_independiente"
            :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo_trabajador_independiente.$invalid}"
          >
            <option :value="null">Seleccione una opción</option>
            <option :value="true">SI</option>
            <option :value="false">NO</option>
          </select>
          <small class="form-message text-danger"
                 v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_trabajador_independiente.$invalid">
            Éste campo es obligatorio
          </small>
        </div>
        <div class="form-group d-flex align-end" v-if="percepcionCargasFamilia.otro_empleo_trabajador_independiente">
          <label class="form-label">Última DDJJ</label>
          <label class="d-flex left btn-flat-dark text-small form-control"
                 :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.otro_empleo_ultima_ddjj.$invalid}"
                 style="padding: .5rem"
                 for="otro_empleo_ultima_ddjj">
            <icon feather="edit" style="height:1rem"/>
            <span v-if="percepcionCargasFamilia.otro_empleo_ultima_ddjj" class="px-1" style="margin-top: .25rem">
                  {{ percepcionCargasFamilia.otro_empleo_ultima_ddjj_original_name }}
            </span>
            <span v-else class="px-1"
                  style="margin-top: .25rem">Subir última ddjj</span>
            <input
              type="file"
              id="otro_empleo_ultima_ddjj"
              @change="changeEditOtroEmpleoUltimaDDJJ"
              style="display: none"
            />
          </label>
          <small class="form-message text-danger"
                 v-if="enviado && $v.percepcionCargasFamilia.otro_empleo_ultima_ddjj.$invalid"
          >Éste campo es obligatorio.</small
          >
        </div>
      </div>
      <template
        v-if="!percepcionCargasFamilia.otro_empleo || !percepcionCargasFamilia.otro_empleo_percibe_asignaciones"
      >
        <h4>Datos del otro progenitor</h4>
        <div class="cols2">
          <pd-input
            v-model="percepcionCargasFamilia.dni_progenitor"
            id="dni_progenitor"
            label="DNI progenitor *"
            placeholder="DNI progenitor"
            type="number"
            :required="enviado && $v.percepcionCargasFamilia.dni_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.cuil_progenitor"
            id="cuil_progenitor"
            type="number"
            label="CUIL progenitor *"
            placeholder="CUIL progenitor"
            :required="enviado && $v.percepcionCargasFamilia.cuil_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.nombre_progenitor"
            id="nombre_progenitor"
            label="Nombre progenitor *"
            placeholder="Nombre progenitor"
            :required="enviado && $v.percepcionCargasFamilia.nombre_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.fecha_nacimiento_progenitor"
            id="fecha_nacimiento_progenitor"
            label="Fecha nacimiento progenitor *"
            placeholder="Fecha nacimiento progenitor"
            type="date"
            :required="enviado && $v.percepcionCargasFamilia.fecha_nacimiento_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.nacionalidad_progenitor"
            id="nacionalidad_progenitor"
            label="Nacionalidad progenitor *"
            placeholder="Nacionalidad progenitor"
            :required="enviado && $v.percepcionCargasFamilia.nacionalidad_progenitor.$invalid"
          />
        </div>
        <div class="cols2 mt-1">
          <pd-input
            v-model="percepcionCargasFamilia.calle_domicilio_progenitor"
            id="calle_domicilio_progenitor"
            label="Calle *"
            placeholder="Calle"
            :required="enviado && $v.percepcionCargasFamilia.calle_domicilio_progenitor.$invalid"
          />
          <div class="cols3" style="margin:0">
            <pd-input
              v-model="percepcionCargasFamilia.numero_domicilio_progenitor"
              id="numero_domicilio_progenitor"
              label="Número *"
              placeholder="Número"
              type="number"
              :required="enviado && $v.percepcionCargasFamilia.numero_domicilio_progenitor.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.piso_domicilio_progenitor"
              id="piso_domicilio_progenitor"
              label="Piso"
              placeholder="Piso"
            />
            <pd-input
              v-model="percepcionCargasFamilia.dpto_domicilio_progenitor"
              id="dpto_domicilio_progenitor"
              label="Dpto"
              placeholder="Dpto"
            />

          </div>
        </div>
        <div class="cols2">
          <pd-input
            v-model="percepcionCargasFamilia.localidad_progenitor"
            id="localidad_progenitor"
            label="Localidad progenitor *"
            placeholder="Localidad progenitor"
            :required="enviado && $v.percepcionCargasFamilia.localidad_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.codigo_postal_progenitor"
            id="codigo_postal_progenitor"
            label="Código postal progenitor *"
            placeholder="Código postal progenitor"
            type="number"
            :required="enviado && $v.percepcionCargasFamilia.codigo_postal_progenitor.$invalid"
          />
          <pd-input
            v-model="percepcionCargasFamilia.provincia_progenitor"
            id="provincia_progenitor"
            label="Provincia progenitor *"
            placeholder="Provincia progenitor"
            :required="enviado && $v.percepcionCargasFamilia.provincia_progenitor.$invalid"
          />
        </div>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">¿Tiene empleo? *</label>
            <select
              id="otro_empleo"
              class="form-control"
              v-model="percepcionCargasFamilia.tiene_empleo_progenitor"
              :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.tiene_empleo_progenitor.$invalid}"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.tiene_empleo_progenitor.$invalid">
              Éste campo es obligatorio
            </small>
          </div>
        </div>
        <template v-if="percepcionCargasFamilia.tiene_empleo_progenitor">
          <div class="cols2">
            <h5 class="my-1 mx-1">Datos empleo de progenitor</h5>
            <div></div>
            <div class="form-group d-flex align-end">
              <label class="form-label">Recibo de sueldo *</label>
              <label class="d-flex left btn-flat-dark text-small form-control"
                     :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.empleo_progenitor_recibo_sueldo.$invalid}"
                     style="padding: .5rem"
                     for="empleo_progenitor_recibo_sueldo_edit">
                <icon feather="edit" style="height:1rem"/>
                <span v-if="percepcionCargasFamilia.empleo_progenitor_recibo_sueldo" class="px-1"
                      style="margin-top: .25rem">
                  {{ percepcionCargasFamilia.empleo_progenitor_recibo_sueldo_original_name }}
                </span>
                <span v-else class="px-1"
                      style="margin-top: .25rem">Subir recibo de sueldo</span>
                <input
                  type="file"
                  id="empleo_progenitor_recibo_sueldo_edit"
                  @change="changeEditEmpleoProgenitorReciboSueldo"
                  style="display: none"
                />
              </label>
              <small class="form-message text-danger"
                     v-if="enviado && $v.percepcionCargasFamilia.empleo_progenitor_recibo_sueldo.$invalid"
              >Éste campo es obligatorio.</small
              >
            </div>
            <div></div>
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_cuit_empleador"
              id="empleo_progenitor_cuit_empleador"
              label="CUIT empleador *"
              type="number"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_cuit_empleador.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_razon_social_empleador"
              id="empleo_progenitor_razon_social_empleador"
              label="Razón social empleador *"
              type="text"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_razon_social_empleador.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_calle_domicilio_empleador"
              id="empleo_progenitor_calle_domicilio_empleador"
              label="Calle *"
              type="text"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_calle_domicilio_empleador.$invalid"
            />
            <div class="cols3" style="margin:0">
              <pd-input
                v-model="percepcionCargasFamilia.empleo_progenitor_numero_domicilio_empleador"
                id="empleo_progenitor_numero_domicilio_empleador"
                label="Número *"
                type="number"
                :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_numero_domicilio_empleador.$invalid"
              />
              <pd-input
                v-model="percepcionCargasFamilia.empleo_progenitor_piso_domicilio_empleador"
                id="empleo_progenitor_piso_domicilio_empleador"
                label="Piso"
                type="text"
              />
              <pd-input
                v-model="percepcionCargasFamilia.empleo_progenitor_dpto_domicilio_empleador"
                id="empleo_progenitor_dpto_domicilio_empleador"
                label="Dpto"
                type="text"
              />
            </div>
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_codigo_postal_empleador"
              id="empleo_progenitor_codigo_postal_empleador"
              label="Código postal *"
              type="number"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_codigo_postal_empleador.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_localidad_empleador"
              id="empleo_progenitor_localidad_empleador"
              label="Localidad *"
              type="text"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_localidad_empleador.$invalid"
            />
            <pd-input
              v-model="percepcionCargasFamilia.empleo_progenitor_provincia_empleador"
              id="empleo_progenitor_provincia_empleador"
              label="Provincia *"
              type="text"
              :required="enviado && $v.percepcionCargasFamilia.empleo_progenitor_provincia_empleador.$invalid"
            />
          </div>
          <div class="cols2">
            <div class="form-group">
              <label class="form-label">¿Percibe asignaciones familiares en el otro empleo? *</label>
              <select
                id="empleo_progenitor_percibe_asignaciones"
                class="form-control"
                v-model="percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones"
                :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones.$invalid}"
              >
                <option :value="null">Seleccione una opción</option>
                <option :value="true">SI</option>
                <option :value="false">NO</option>
              </select>
              <small class="form-message text-danger"
                     v-if="enviado && $v.percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones.$invalid">
                Éste campo es obligatorio
              </small>
            </div>
            <div class="form-group d-flex align-end"
                 v-if="!percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones">
              <label class="form-label">Certificado de no percepción</label>
              <label class="d-flex left btn-flat-dark text-small form-control"
                     :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion.$invalid}"
                     style="padding: .5rem"
                     for="empleo_progenitor_certificado_no_percepcion">
                <icon feather="edit" style="height:1rem"/>
                <span v-if="percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion" class="px-1"
                      style="margin-top: .25rem">
                    {{ percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion_original_name }}
                </span>
                <span v-else class="px-1"
                      style="margin-top: .25rem">Subir certificado de no percepción</span>
                <input
                  type="file"
                  id="empleo_progenitor_certificado_no_percepcion"
                  @change="changeEditEmpleoProgenitorCertificadoNoPercepcion"
                  style="display: none"
                />
              </label>
              <small class="form-message text-danger"
                     v-if="enviado && $v.percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion.$invalid"
              >Éste campo es obligatorio.</small
              >
            </div>
          </div>
        </template>
        <div class="cols2">
          <div class="form-group">
            <label class="form-label">¿Trabaja en forma independiente (Autónomo/Monotributista)? *</label>
            <select
              id="empleo_progenitor_trabajador_independiente"
              class="form-control"
              v-model="percepcionCargasFamilia.empleo_progenitor_trabajador_independiente"
            >
              <option :value="null">Seleccione una opción</option>
              <option :value="true">SI</option>
              <option :value="false">NO</option>
            </select>
          </div>
          <div class="form-group d-flex align-end" v-if="percepcionCargasFamilia.empleo_progenitor_trabajador_independiente">
            <label class="form-label">Última DDJJ</label>
            <label class="d-flex left btn-flat-dark text-small form-control"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.empleo_progenitor_ultima_ddjj.$invalid}"
                   style="padding: .5rem"
                   for="empleo_progenitor_ultima_ddjj">
              <icon feather="edit" style="height:1rem"/>
              <span v-if="percepcionCargasFamilia.empleo_progenitor_ultima_ddjj" class="px-1"
                    style="margin-top: .25rem">
                    {{ percepcionCargasFamilia.empleo_progenitor_ultima_ddjj_original_name }}
              </span>
              <span v-else class="px-1"
                    style="margin-top: .25rem">Subir última DDJJ</span>
              <input
                type="file"
                id="empleo_progenitor_ultima_ddjj"
                @change="changeEditEmpleoProgenitorUltimaDDJJ"
                style="display: none"
              />
            </label>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.empleo_progenitor_ultima_ddjj.$invalid"
            >Éste campo es obligatorio.</small
            >
          </div>
        </div>
        <template
          v-if="
            !percepcionCargasFamilia.otro_empleo ||
            (percepcionCargasFamilia.otro_empleo && !percepcionCargasFamilia.otro_empleo_percibe_asignaciones) ||
            !percepcionCargasFamilia.tiene_empleo_progenitor ||
            (percepcionCargasFamilia.tiene_empleo_progenitor && !percepcionCargasFamilia.empleo_progenitor_percibe_asignaciones)
          "
        >
          <h5>Causas por las cuales el otro progenitor no percibe asignaciones
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid"
            >Éste campo es obligatorio.</small
            ></h5>
          <div class="w-100">
            <div class="my-1 cols3">
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="trabajador_independiente" value="Trabajador independiente"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="trabajador_independiente">Trabajador independiente</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="residiendo_en_extranjero" value="Residiendo en el extranjero"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="residiendo_en_extranjero">Residiendo en el extranjero</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="jubilado" value="Jubilado"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="jubilado">Jubilado</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="fallecido" value="Fallecido"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="fallecido">Fallecido</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="privado_libertad" value="Privado de la libertad"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="privado_libertad">Privado de la libertad</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="desocupado" value="Desocupado"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="desocupado">Desocupado</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="trabaja_estado" value="Trabaja en el estado provincial"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="trabaja_estado">Trabaja en el estado provincial</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="soltero_separado"
                       value="Soltero/Separado//Divorciado teniendo padre/madre a su cargo los hijos"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="soltero_separado">Soltero/Separado//Divorciado teniendo
                  padre/madre
                  a su cargo los hijos</label>
              </div>
              <div class="form-control radio-container"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}">
                <input type="radio" id="otro"
                       :value="causaProgenitorOtrosValue"
                       v-model="percepcionCargasFamilia.causas_no_percepcion_progenitor"/>
                <label style="padding-left:.5rem;" for="otro">Otros: </label>
                <input type="text" class="form-control mx-1" placeholder="Describa la causa"
                       :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.causas_no_percepcion_progenitor.$invalid}"
                       id="causa_progenitor_otro" @input="checkOtro"/>
              </div>
            </div>
          </div>
          <div class="form-group d-flex align-end">
            <label class="form-label">Autorización otro progenitor</label>
            <label class="d-flex left btn-flat-dark text-small form-control"
                   :class="{'is-invalid': enviado && $v.percepcionCargasFamilia.autorizacion_progenitor.$invalid}"
                   style="padding: .5rem"
                   for="autorizacion_progenitor">
              <icon feather="edit" style="height:1rem"/>
              <span v-if="percepcionCargasFamilia.autorizacion_progenitor" class="px-1"
                    style="margin-top: .25rem">
                    {{ percepcionCargasFamilia.autorizacion_progenitor_original_name }}
              </span>
              <span v-else class="px-1"
                    style="margin-top: .25rem">Adjuntar autorización otro progenitor </span>
              <input
                type="file"
                id="autorizacion_progenitor"
                @change="changeAutorizacionProgenitor"
                style="display: none"
              />
            </label>
            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.autorizacion_progenitor.$invalid"
            >Éste campo es obligatorio.</small
            >
          </div>

          <div class="form-control my-2">
            <input type="checkbox" id="solo_datos_progenitor"
                   v-model="percepcionCargasFamilia.solo_datos_progenitor"/>
            <label style="padding-left:.5rem;" for="solo_datos_progenitor">DDJJ para acreditación de datos de progenitores</label>
          </div>
          <template v-if="!percepcionCargasFamilia.solo_datos_progenitor ">

            <h4>Hijos a cargo del trabajador/a menores de 18 años</h4>
            <div class="my-1">
              <button type="button" @click="openAddHijo = true" class="btn btn-info btn-sm">+ Añadir hijo</button>
            </div>
            <table class="table-list" :class="{'table-error': enviado && $v.percepcionCargasFamilia.hijos.$invalid}">
              <thead>
              <tr>
                <th>CUIL</th>
                <th>Nombre</th>
                <th>Fecha de nacimiento</th>
                <th>Lugar</th>
                <th>DNI</th>
                <th>Discapacidad</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(hijo, index) in percepcionCargasFamilia.hijos" :key="hijo.cuil">
                <td>{{ hijo.cuil }}</td>
                <td>{{ hijo.nombre }}</td>
                <td>{{ formatDate(hijo.fecha_nacimiento) }}</td>
                <td>{{ hijo.lugar }}</td>
                <td>{{ hijo.dni }}</td>
                <td>{{ hijo.discapacidad ? "SI" : "NO" }}</td>
                <td>
                  <button class="btn btn-sm btn-warning text-small" type="button" @click="openEditHijoModal(index)">
                    Editar
                  </button>
                  <button class="btn btn-sm btn-error text-small" type="button" @click="deleteHijo(hijo)">
                    Eliminar
                  </button>
                </td>
              </tr>
              <tr class="text-center" v-if="!percepcionCargasFamilia.hijos.length">
                <td colspan="7">No hay hijos cargados</td>
              </tr>
              </tbody>
            </table>

            <small class="form-message text-danger"
                   v-if="enviado && $v.percepcionCargasFamilia.hijos.$invalid"
            >Éste campo es obligatorio.</small
            >
          </template>
        </template>
      </template>
      <div class="mt-2">
        <button class="btn btn-right btn-primary">Firmar declaración jurada</button>
      </div>
    </form>

    <modal v-if="openAddHijo" @close="openAddHijo = false">
      <CrearPercepcionCargasFamiliaHijos slot="modal-body" @saved="hijoSaved"/>
    </modal>

    <modal v-if="openEditHijo" @close="openEditHijo = false">
      <EditPercepcionCargasFamiliaHijos :selected-hijo="percepcionCargasFamilia.hijos[selectedHijoIndex]"
                                        slot="modal-body" @saved="hijoEdited"/>
    </modal>
  </div>
</template>
<script>
import CrearPercepcionCargasFamiliaHijos
  from "@/modules/percepcionesAsignacionFamiliar/components/CrearPercepcionCargasFamiliaHijos.vue";
import Modal from "@/components/elements/Modal.vue";
import {
  percepcionCargasFamiliaForm,
  percepcionCargsFamiliaValidation
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaForm";
import {formatDate} from "@/helpers/dateFormatter";
import EditPercepcionCargasFamiliaHijos
  from "@/modules/percepcionesAsignacionFamiliar/components/EditPercepcionCargasFamiliaHijos.vue";

export default {
  name: "FormPercepcionCargasFamilia",
  components: {EditPercepcionCargasFamiliaHijos, Modal, CrearPercepcionCargasFamiliaHijos},
  emits: ['submitted'],
  props: {
    user: {
      type: Object,
      required: true
    },
    asignacionFamiliar: {
      type: Object,
      default: () => {
      }
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      percepcionCargasFamilia: percepcionCargasFamiliaForm(this.user, this.asignacionFamiliar),
      enviado: false,
      openAddHijo: false,
      openEditHijo: false,
      selectedHijoIndex: null,
      causasNoPercepcion: [
        "Trabajador independiente",
        "Residiendo en el extranjer0",
        "Jubilado",
        "Fallecido",
        "Privado de la libertad",
        "Desocupado",
        "Trabaja en el estado provincial",
        "Soltero/Separado//Divorciado teniendo padre/madre a su cargo los hijos",
      ]
    }
  },
  computed: {
    years: () => {
      let years = [];
      for (let i = 2022; i <= 2040; i++) {
        years.push(i)
      }
      return years;
    },
    causaProgenitorOtrosValue() {
      return document.querySelector('#causa_progenitor_otro')?.value
    },
    mapInvalidProperties() {
      return Object.keys(this.$v.percepcionCargasFamilia).map((key) => {
        return {key: key, invalid: this.$v.percepcionCargasFamilia[key].$invalid}
      }).filter((value) => value.invalid)
    }
  },
  validations: {
    percepcionCargasFamilia: percepcionCargsFamiliaValidation()
  },
  watch: {
    'percepcionCargasFamilia.causas_no_percepcion_progenitor'(newval) {
      const inputCausaOtros = document.querySelector('#causa_progenitor_otro')
      if (newval !== inputCausaOtros?.value) {
        inputCausaOtros.value = ''
        document.querySelector('#otro').value = ''
        document.querySelector('#otro').checked = false
      }
    }
  },
  mounted() {
    const inputCausaOtros = document.querySelector('#causa_progenitor_otro')
    if (!this.asignacionFamiliar?.id && !this.causasNoPercepcion.find(causa => causa === this.percepcionCargasFamilia.causas_no_percepcion_progenitor)) {
      inputCausaOtros.value = this.percepcionCargasFamilia.causas_no_percepcion_progenitor ?? ''
      document.querySelector('#otro').value = this.percepcionCargasFamilia.causas_no_percepcion_progenitor ?? ''
      document.querySelector('#otro').checked = true
    } else {
      document.querySelector('#otro').checked = false
    }
  },
  methods: {
    changeEditOtroEmpleoReciboSueldo(e) {
      this.percepcionCargasFamilia.otro_empleo_recibo_sueldo = e.target.files[0]
      this.percepcionCargasFamilia.otro_empleo_recibo_sueldo_original_name = e.target.files[0].name
    },
    changeEditEmpleoProgenitorReciboSueldo(e) {
      this.percepcionCargasFamilia.empleo_progenitor_recibo_sueldo = e.target.files[0]
      this.percepcionCargasFamilia.empleo_progenitor_recibo_sueldo_original_name = e.target.files[0].name
    },
    changeAutorizacionProgenitor(e) {
      console.log(e.target.files[0])
      console.log(this.percepcionCargasFamilia.autorizacion_progenitor)
      this.percepcionCargasFamilia.autorizacion_progenitor = e.target.files[0]
      this.percepcionCargasFamilia.autorizacion_progenitor_original_name = e.target.files[0].name
      console.log(this.percepcionCargasFamilia)
    },
    changeEditOtroEmpleoCertificadoNoPercepcion(e) {
      this.percepcionCargasFamilia.otro_empleo_certificado_no_percepcion = e.target.files[0]
      this.percepcionCargasFamilia.otro_empleo_certificado_no_percepcion_original_name = e.target.files[0].name
    },
    changeEditOtroEmpleoUltimaDDJJ(e) {
      this.percepcionCargasFamilia.otro_empleo_ultima_ddjj = e.target.files[0]
      this.percepcionCargasFamilia.otro_empleo_ultima_ddjj_original_name = e.target.files[0].name
    },
    changeEditEmpleoProgenitorCertificadoNoPercepcion(e) {
      this.percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion = e.target.files[0]
      this.percepcionCargasFamilia.empleo_progenitor_certificado_no_percepcion_original_name = e.target.files[0].name
    },
    changeEditEmpleoProgenitorUltimaDDJJ(e) {
      this.percepcionCargasFamilia.empleo_progenitor_ultima_ddjj = e.target.files[0]
      this.percepcionCargasFamilia.empleo_progenitor_ultima_ddjj_original_name = e.target.files[0].name
    },
    checkOtro(e) {
      this.percepcionCargasFamilia.causas_no_percepcion_progenitor = e.target.value
      document.querySelector('#otro').checked = true
    },
    formatDate,
    save() {
      this.enviado = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.mapInvalidProperties)
        return false;
      }
      this.$emit('submitted', this.percepcionCargasFamilia)
    },
    hijoSaved(hijo) {
      this.percepcionCargasFamilia.hijos.push(hijo)
      this.openAddHijo = false
    },
    openEditHijoModal(index) {
      this.openEditHijo = true;
      this.selectedHijoIndex = index
    },
    hijoEdited(hijo) {
      this.percepcionCargasFamilia.hijos[this.selectedHijoIndex] = hijo
      this.openEditHijo = false
      this.selectedHijoIndex = null
    },
    deleteHijo(hijo) {
      this.percepcionCargasFamilia.hijos = this.percepcionCargasFamilia.hijos.filter((percepHijo) => percepHijo !== hijo)
    }
  }
}
</script>

<style scoped>

</style>
